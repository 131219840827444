body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Fancy";   /*Can be any text*/
  src: local("CormorantGaramond-Regular"),
    url("./fonts/CormorantGaramond-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Fancy-Bold";   /*Can be any text*/
  src: local("CormorantGaramond-Bold"),
    url("./fonts/CormorantGaramond-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Futuristic";   /*Can be any text*/
  src: local("Tektur-Regular"),
    url("./fonts/Tektur-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Modern";   /*Can be any text*/
  src: local("Urbanist-Regular"),
    url("./fonts/Urbanist-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sleek";   /*Can be any text*/
  src: local("Montserrat-Regular"),
    url("./fonts/Montserrat-Regular.ttf") format("truetype");
}